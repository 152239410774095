import type { MessageFunction } from 'vue-i18n';
import order from './pages/order';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import { vehicle_country } from './pages/vehicle/logistics';

export default {
    license: {
        // eslint-disable-next-line @typescript-eslint/quotes
        powered_by: "上汽国际 {'|'} 版权所有",
    },
    auth: {
        login: '登录',
        username: '用户名',
        username_placeholder: '请输入用户名',
        password: '密码',
        password_placeholder: '请输入密码',
        code: '验证码',
        code_placeholder: '请输入验证码',
        logout: '注销',
        logout_success: '注销成功',
        day: '天',
        password_expiration: '密码已到期请修改',
        expire: '距离密码到期还剩',
        conseils: '必须包含大写字母、小写字母、数字、特殊符号（{msg}），密码长度在 8-16 位',
    },
    button: {
        query: '查询',
        editing_position: '编辑岗位',
        show_theme_editor: '显示/隐藏主题编辑器',
        change_password: '修改密码',
        old_password: '旧密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        cipher: '密码',
        change_new_password: '请填写新密码',
        button: '按钮',
        about: '关于',
        back: '返回',
        go: '确定',
        home: '首页',
        toggle_dark: '切换深色模式',
        toggle_langs: '切换语言',
        confirm: '确定',
        modify: '修改',
        edit: '编辑',
        new: '新增',
        cancel: '取消',
        close: '关闭',
        search: '搜索',
        reset: '重置',
        delete: '删除',
        add: '新增',
        collapse: '收起',
        expand: '展开',
        download_template: '下载模板',
        batch_import: '批量导入',
        import: '导入',
        export: '导出',
        export_fail: '导入失败',
        batch_clear_sap_code: '批量清空SAP Code',
        batch_clear_special_purchase_cycle: '批量清空特殊采购周期',
        detailed: '明细',
        download: '下载',
        toView: '查看',
        toViews: '查看图片',
        uploadAttachments: '上传附件',
        selectFile: '选择文件',
        fillingInstructions: '填写说明',
        release: '发布',
        save: '保存',
        tips: '提示',
        send_back: '退回',
        examine: '审核',
        create_sap: '创建SAP',
        update: '更新',
        updateTime: '更新有效期',
        ilterOptionsupdate: '更新',
        void: '作废',
        more: '更多',
        refresh: '刷新',
        submit_sap: '提交SAP',
        submit: '提交',
        save_button: '保存并新建',
        insufficient: '账户资金不足是否提交',
        yes: '是',
        deny: '否',
        staging: '暂存',
        return: '退回',
        table: '序号',
        select: '选择',
        all: '共',
        enter: '请输入',
        selects: '请选择',
        please_search: '请点击查询',
        modify_ladder: '修改阶梯',
        pass: '通过',
        serial_number: '序号',
        individual: '个',
        upload_progress: '上传进度',
        port_choose: '港口选择',
        request_code: '请勾选配件',
        request_discuss: '请选择供应商',
        request_dt: '请勾选DT',
        request_thing: '请勾选物料小类',
        accessories_information: '配件信息',
        select_accessories: '选择配件',
        binding: '绑定',
        tick_data: '请勾选数据',
        invalid: '作废',
        confirm_number: '确认箱单号:',
        empty_data: '清空生成数据',
        variance_details: '差异明细',
        daily_record: '日志',
        operation_type: '操作类型',
        field_name: '字段名',
        edit_role: '编辑角色',
        user_role: '选择角色',
        assignment_role: '角色',
        post: '岗位',
        please_settled: '请选择要结算的配件',
        not_sent: '未发送',
        sending: '发送中',
        has_been_sent: '已发送',
        fail_in_send: '发送失败',
        express_import: '快递导入',
        reset_password: '重置密码',
        display_or_not: '是否显示',
        password_reset: '密码重置',
        batch_cancellation: '批量取消',
        assistance: '帮助',
        currentTotal: '当前共计条数：',
        batch_confirmation: '批量确认',
        batch_cancellation2: '批量作废',
        batch_approval: '批量审批',
        generate_statement: '生成对账单',
        approved: '是否审核通过',
        returned_reason: '退回原因',
        tip: '提示',
        newadd: '新增',
    },
    radio: {
        enabled: '启用',
        disabled: '停用',
        catalogue: '目录',
        individual: '个体',
        all: '全部',
        yes: '是',
        no: '否',
        confirmed: '确定',
        cancel: '取消',
    },
    dropdown: {
        close_others: '关闭其他',
        close_right: '关闭右侧',
        close_left: '关闭左侧',
    },
    table: {
        action: '操作',
        explain: '说明',
        fileName: '文件名',
        size: '大小(MB)',
        uploader: '上传人',
        uploadTime: '上传时间',
        system: '系统',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/页`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `共 ${named('number')} 条数据`,
    },
    confirm: {
        confirm_deletion: '确认删除?',
        confirm_void: '确认作废?',
        confirm_release: '确认发布?',
        ack: '确认',
        cancel: '取消',
        non_records: '请至少选择一条数据',
    },
    request: {
        failed_placeholder: '请求失败，服务端错误',
        success_placeholder: '请求成功',
        auth: {
            login_success: '登录成功',
        },
        error: {
            '404': '请求路径不存在',
            '403': '权限不足',
        },
    },
    page: {
        global: {
            page_search: '页面搜索',
        },
        error: {
            not_found: '未找到页面',
            not_found_des: '这里没有任何东西',
            auth_failed: '权限不足',
            auth_failed_des: '此页面禁止访问',
        },
        homepage: {
            homepage: homepage.homepage.zhCN,
        },
        system: {
            menu: {
                menu: '菜单',
                menu_name: '菜单名称',
                button_name: '按钮名称',
                purview: '权限编码',
                menu_type: '菜单类型',
                menu_new: '菜单新增',
                menu_edit: '菜单编辑',
                icon: '图标',
                parent: '父级菜单',
                order_number: '排序',
                path: '路由地址',
                status: '菜单状态',
            },
            dict: {
                dictionary_name: '字典名称',
                creater: '创建人',
                dictionary_code: '字典代码',
                code_type: '类型代码',
                code_type_name: '类型名称',
                creation_time: '创建时间',
                dict_new: '字典新增',
                dict_edit: '字典编辑',
                lang: '语言',
                sort: '排序',
            },
            dept: {
                system_selection: '系统选择',
                parent_dept: '上级部门',
                dept_name: '组织',
                leader: '负责人',
                email: '邮箱',
                create_by: '创建人',
                create_date: '创建时间',
                organization_editing: '组织编辑',
                phone: '电话',
                organizational_type: '组织类型',
            },
            role: {
                menu_maintenance: '菜单维护',
                role_name: '角色名称',
                role_key: '角色代码',
                role_id: '显示顺序',
                status: '状态',
                create_date: '创建时间',
                role_sort: '排序',
                role_new: '角色新增',
                role_edit: '角色编辑',
                role_bleak: '菜单',
            },
            user: {
                serial_no: '序号',
                username: ' 用户名',
                name: '姓名',
                sex: '性别',
                phone_number: '手机号',
                email: '邮箱',
                role: '角色',
                login_time: '启用状态',
                password: '密码',
                user_edit: '用户编辑',
            },
        },
        management: {
            purchasePosting: {
                order_status: 'SLK订单状态',
                transportation_method: '订单类型，运输方式，贸易条款不同，请勾选类型一样的订单！',
                whether_to_send: '是否发送SAP',
                invoice_number: '发票号码',
                sales_purchase_tax_code: '销售/购买税代码',
                invoice_reversal: '发票冲销',
                charge_against: '冲销',
                send_sap: '发送SAP',
                whether_notify_invoicing: '是否通知开票',
                import_invoices: '导入发票',
                import_warehouse_time: '进仓确认导入',
                import_information: '导入报关信息',
                invoicing_notice: '开票通知',
                tax_rate: '税率',
                section: '项',
                prepayment_not: '是否预付款',
                prepayment_note_no: '预付款单号',
                list_to_be_invoiced: '待开票列表',
                invoiced_but_unpaid_list: '已开票未付款列表',
                invoiced_and_paid_list: '已开票已付款列表',
                dn_no: 'DN号',
                sub_order_no: '子订单',
                packing_list_no: '箱单号',
                brand: '品牌',
                whether_invoicable: '是否可开票',
                payment_type: '付款类型',
                advance_payment: '预付款',
                oem_order_no: '主机厂订单号',
                tax_amount: '税额',
                invoice_amount: '发票金额',
                invoice_tax_amount: '发票税额',
                amount_including_tax: '金额',
                amount_excluding_tax: '金额',
                amount_of_money: '金额',
                payment_time: '付款时间',
                payment_order_no: '付款单号',
                sap_voucher_No: 'SAP凭证号',
                export_gold_tax: '导出金税',
                export_maxus: '导出MAXUS开票清单',
                time_of_payment: '付款时间',
                apply_for_payment: '申请付款',
                outbound_confirmation: '出仓确认',
                export_confirmation_import: '出仓确认导入',
            },
            preBooking: {
                booking: '预订舱',
                booking_space: '订舱',
                packing_list_no: '箱单号',
                job_no: 'Job No',
                dangerous_goods_or_not: '是否危险品',
                brand: '品牌',
                type_of_shipping: '运输方式',
                dt_code: 'DT代码',
                estimated_volume: '预估体积(m³)',
                estimated_gross_weight: '预估毛重(kg)',
                estimated_net_weight: '预估净重(kg)',
                estimated: '预估箱量',
                volume: '体积(m³)',
                gross_weight: '毛重(kg)',
                net_weight: '净重(kg)',
                shipping_schedule: 'Job No船期',
                shipping_schedules: 'Job No船期不能为空',
                shipping_type: '发运类型',
                box_quantity: 'Job No预估箱量',
            },
            shoppingCart: {
                bo_cancellation: 'BO取消原因',
                external_order_no: 'DCS订单编号',
                dcs_order_number: 'DCS订单号',
                vin_requires: 'VIN 需要17位以3位大小字母开头的字母与数字组合',
                part_type: '零件类型',
                submit_validation: '提交效验',
                maximum_value: '最大值9999999999',
                please_select_cart: '请选择要加入购物车的配件',
                please_enter_format: '请输入PDF、XLSX、XLS格式、WORD格式',
                only_supports: '3.只支持PDF、XLSX、XLS格式、WORD格式的上传限大小5M',
                Only_limits_of: '注:只支持jpg、png、gif格式的上传限大小5M',
                please_format: '请输入jpg、png、gif格式',
                please_enter_an_integer: '请输入正确的订货数量',
                customized_orders: '定制类订单',
                details_accessory: '配件代码明细',
                warning: '确认删除?',
                please_payment_method: '请选择支付方式',
                please_port: '请选择港口',
                please_party: '请选择送达方',
                please_enter_a_note: '请输入备注',
                no_inventory: '无库存',
                notes: '注',
                note_the: '定制件未维护VIN/附件',
                download_template: '下载模板',
                single_addition: '单项新增',
                material_category: '物料小类',
                order_type: '订单类型',
                accessory_code: '配件代码',
                part_name: '配件名称',
                term_of_trade: '贸易条款',
                logistics_mode: '物流方式',
                type_of_fittings: '配件类型',
                available_balance: '可用余额',
                payable_amount: '应付金额',
                order_deadline: '下单截至日',
                place_order: '提交订单',
                order_information: '订单信息',
                order_No: '订单编号',
                supplier: '供货方',
                creation_date: '创建日期',
                type_of_shipping: '运输方式',
                payment_method: '付款方式',
                port: '港口',
                delivered_by: '送达方',
                remarks: '备注',
                brand: '品牌',
                currency: '币种',
                accessory: '配件行数',
                number_accessories: '配件数量',
                amount_accessories: '配件金额',
                freight: '运费(预估)',
                premium: '保费',
                order_amount: '订单金额',
                total_transportation: '运输总体积(m³)',
                ordinary_goods: '普货预计发运日期',
                arrival_date: '危险品预计发运日期',
                accessories_information: '配件信息',
                whole: '全部',
                routine: '常规',
                conventional_parts: '常规件',
                dangerous_goods: '危险品',
                customized: '定制',
                customized_parts: '定制件',
                chemicals: '化工品',
                oily_parts: '含油液零件',
                commodity_inspection_parts: '商检件',
                incorrect: '输入数据有误输入数据有误',
                data_empty: '数据不能为空',
                cannot_time: '不能小于当前时间',
                cannot_current_time: '不能大于当前时间',
                payment: '付款凭证号不能为空',
                permits: '许可证件',
                accessories_selection: '配件选择',
                forbidden_empty: '是否禁空',
                add_cart: '加入购物车',
                order_number: '序号',
                replacement: '替代件',
                order_quantity: '订货数量',
                unit_price: '单价',
                fob_unit_price: 'FOB单价',
                company: '单位',
                be_purchased: '是否可采购',
                quantity: '最小起订量',
                total_amount: '总金额',
                volumes: '体积(m³)',
                volume: '体积',
                enclosure: '附件',
                confirmed_quantity: '确认数量',
                courier: '航班/船号/快递',
                future_order: 'Future order类型',
                please_future_order: '请选择Future order类型',
                urgent_order_reason: '紧急订单原因',
                please_urgent_order: '请选择紧急订单原因',
                single_packet_delivery: '单包单发',
            },
            salesquery: {
                actual_delivery: '实际发货日期',
                estimated_delivery: '预计发货日期',
                total_package: '包装总净重',
                total_gross: '包装总毛重',
                trade_mode: '改运后贸易方式',
                mode_before: '改运前贸易方式',
                port_after_transshipment: '改运后港口',
                port_diversion: '改运前港口',
                outer: '外箱尺寸(高mm)',
                outer_box_size: '外箱尺寸(宽mm)',
                outer_box: '外箱尺寸(长mm)',
                volume: '体积(m³)',
                gross_weight: '毛重(kg)',
                net_weight: '净重(kg)',
                shipment_quantity: '发货数量',
                ordering_parts_code: '订货配件代码',
                packing_code: '箱号',
                box_no: '包装序号',
                container_no: '集装箱号',
                sales_order_information: '销售单信息',
                packing_list_no: '箱单号',
                accessory_code: '配件代码',
                order_no: '订单编号',
                merge_ci: '导出合并的CI&PL',
                export_ci: '导出CI',
                export_pl: '导出PL',
                currency: '币种',
                parts_amount: '配件金额',
                freight: '运费',
                premium: '保费',
                other_expenses: '其他费用',
                deduction_of_claims: '索赔款抵扣',
                total_amoun: '总金额',
                creation_date: '箱单创建日期',
                ship_number: '船号/航班/快递',
                supplier: '供货方',
                accessories: '配件总行数',
                total_accessories: '配件总数量 ',
                gross_weights: '总毛重',
                total_weight: '总净重',
                total_packages: '包装总体积(m³)',
                dt_code: 'DT代码',
                total_volume: '总体积',
                upload_date: '箱单上传日期',
            },
            Balance: {
                date: '日期',
                payment_reference: '付款凭证号',
                suborder_number: '子订单编号',
                packing_list_no: '箱单号',
                amount_type: '资金类型',
                income_expenditure: '入账/支出',
                amount_status: '款项状态',
                expenditure_amount: '支出金额',
                Amount_Income: '待入帐金额',
                income_amount: '入账金额',
                account_amount_balance: '账户金额结余',
                frozen_amount_balance: '冻结结余',
                currency: '币种',
                number: '信用证号',
                term_of_validity: '信用证有效期',
                picture: '信用图片',
                confirmed: '上传信用证凭证',
                order_amount_frozen: '订单资金冻结',
                refund_due_delete: '一键删单后扣除金额退回',
                deduction_of_frozen_amount: '冻结资金扣除',
                account_amount: '账户资金扣除',
                frozen_release_amount: '冻结资金释放',
                frozen_amount_release: '冻结资金结余',
                bo_parts: 'BO配件取消',
                frozen_amount: '冻结金额',
            },
            suborder: {
                packing_details: '箱单明细',
                receipt_reminder: '到账提醒',
                arrive: '到达',
                shipped: '已发运',
                complete_packaging: '完成包装',
                passenger_order_number: '乘用车订单编号',
                order_detail: '订单明细',
                order_details: '子订单明细',
                approved: '审核通过',
                order_submission: '订单提交',
                accessory: '配件',
                order_amount: '订货金额',
                company: '单位',
                name_accessories: '配件名称',
                accessory_code: '配件代码',
                serial_no: '序号',
                submit_smpv: '提交SMPV',
                customs_clearance: 'DCS关单',
                sub_order_no: '子订单编号',
                sub_order_type: '子订单类型',
                brand: '品牌',
                type_hipping: '运输方式',
                dt_code: 'DT代码',
                sub_order_amount: '子订单金额',
                trade_terms: '贸易条款',
                passenger_number: '乘用车订单号',
                dcs_order_number: 'DCS订单编号',
                customs_declaration: '关单',
                Please_enter_number: '请输入DCS订单编号',
                remarks: '备注',
                submission_date: '提交日期',
                supplier: '供货方',
                mode_of_trade: '贸易方式',
                port: '港口',
                to: '送达方',
                currency: '币种',
                cancel_date: '取消日期',
                accessory_information: '配件信息',
            },
            query: {
                export_pi: '导出PI',
                export_po: '导出PO',
                audit_status: '审核状态',
                cancel: '是否取消',
                whether_submit: '是否提交',
                to_cancel: '是否确认取消？',
                submit_results: '提交结果',
                actual_arrival: '实际到货日期',
                estimated_arrival: '预计到货日期',
                packaging_completion_date: '完成包装日期',
                order_no: '订单编号',
                order_status: '订单状态',
                rough_draft: '草稿',
                funds_be_reviewed: '资金待审核',
                approved: '已审核',
                canceled: '已取消',
                order_type: '订单类型',
                creation_date: '创建日期',
                accessory_code: '配件代码',
                mode_transport: '运输方式',
                brand: '品牌',
                export_order_details: '导出订单明细',
                dt_code: 'DT代码',
                order_amount: '订单金额',
                term_of_trade: '贸易条款',
                submission_date: '提交日期',
                DCS_order_number: 'DCS订单编号',
                remarks: '备注',
                upload_credit: '上传信用证',
                lc_no: '信用证编号',
                term_validity: '信用证有效期',
                received_amount: '入账金额',
                amount: '信用证金额',
                picture: '信用证图片',
                empty: '信用证编号不能为空',
                payment_registration: '付款登记',
                payment_voucher_no: '付款凭证号',
                payment_date: '付款日期',
                payment_amoun: '付款金额',
                number_lines: '配件行数',
                total_accessories: '配件总数量',
                estimated_premium: '预估保费',
                estimated_freight: '预估运费',
                estimated_amount: '预估订单总金额',
                estimated_volume: '预估运输总体积(m³)',
                please_upload: '请上传',
                order_tracking: '订单跟踪',
                put_away: '收起',
                more: '更多',
                actually_delivered: '实发配件代码',
                confirm_quantity: '确认数量',
                package_quantity: '包装数量',
                shipment_quantity: '发运数量',
                arrival_quantity: '到达数量',
                cancel_quantity: '取消数量',
                bo_quantity: 'BO数量',
                order_submission: '订单提交',
                warn: '注: 1.在安排转账时,请注明您的',
                wa: '(DT 编号)和(配件)',
                rn: '以便我们确认付款,',
                warnts: '如果遗漏，我们可能无法及时查看您的付款；',
                warns: '2.账户金额以实际到账金额为准；',
                voucher: '请输入付款凭证号',
                please_brand: '请选择品牌',
                payment_dates: '请输入付款日期',
                payment_amount: '请输入付款金额',
                credit_number: '请输入信用证编号',
                validity_period: '请输入有效期',
                letter_credit: '请输入信用证金额',
                upload_picture: '请上传信用证图片',
                part_no: '配件代码',
                part_name: '配件名称',
                quantity: '订货数量',
                accessories: '配件明细',
                company: '船公司',
                ships_name: '船名',
                flights: '航次',
                customs_no: '关单号',
                lading_no: '提单号',
                flight_number: '航班号',
                container_no: '集装箱号',
                packing_list: '箱单号',
                packaging_quantity: '包装数量',
                packaging_completion: '完成包装日期',
                shipment_quantitys: '发货数量',
                delivery_date: '预计发货日期',
                shipment_dates: '实际发货日期',
                arrival_quantitys: '到达数量',
                arrival_date: '预计到货日期',
                actual_arrival_date: '实际到货日期',
                payment_status_query: '付款状态查询',
                total_accessorie: '配件总数',
                export_order_list: '导出订单列表',
            },
            salesManagement: {
                delivery_number: '交货单号',
                booking_email: '订舱邮件',
                additional_fee_amount: '附加费用金额',
                before_modifications: '修改前',
                after_modification: '修改后',
                continue_importing: '继续导入',
                customs_declaration_name: '报关名称',
                customs_declaration_not: '是否报关',
                invoice_no: '发票号',
                contract_no: '合同号',
                attachment_fee_amount: '附加费用金额:',
                xpense_amount: '费用金额',
                import_list: '导入MG箱单',
                import_packing: '导入MAXUS箱单',
                sales_order_list: '销售单列表',
                job_no_list: 'Job No列表',
                dt_code: 'DT代码',
                brand: '品牌',
                packing_list_no: '箱单号',
                packing_list_nos: '原箱单号:',
                bilateral_contract_no: '双边合同号',
                contract_status: '合同状态',
                inbound_delivery: '内向交货单号',
                supplier: '供货方',
                list_creation: '箱单创建日期',
                currency: '币种',
                transit_status: '改运状态',
                split_merge: '拆分/合并',
                sap_return_information: 'SAP返回信息',
                send_booking: '是否发送订舱邮件',
                lcl_or_not: '是否拼箱',
                operate: '操作',
                modify_freight: '修改运费',
                modify_lc: '修改LC',
                detailed: '明细',
                merge: '合并',
                split: '拆分',
                diversion: '改运',
                lcl: '拼箱',
                charges: '附加费用',
                generation: '一键生成单证',
                deletion: '一键删除单证',
                bind_lc: '绑定LC',
                unbind_lc: '解绑LC',
                merge_ci: '导出合并的CI&PL',
                export_ci: '导出CI',
                export_pl: '导出PL',
                export_list: '导出列表',
                cancel_merge: '取消合并',
                remove_split: '取消拆分',
                additional_fees:
                    '取消合并后将清除箱单改运、附加费用、拼箱、集装箱记录，是否继续取消合并？',
                system_prompts:
                    '取消拆分时系统提示：取消拆分后将清除箱单改运、附加费用、拼箱、集装箱记录，是否继续取消拆分',
                additional_charges: '附加费用:',
                packing: '箱单号：',
                expenses: '运费：',
                delivery: '外向交货单号：',
                binding_record: '修改LC绑定记录',
                trade_terms: '贸易条款：',
                logistics_mode: '物流方式：',
                port: '港口：',
                payment_method: '付款方式：',
                please_select: '请选择贸易条款',
                please_logistics_mode: '请选择物流方式',
                please_port: '请选择港口',
                please_payment_method: '请选择支付方式',
                select_export_type: '选择导出类型',
                plase_select_export_type: '请选择导出类型',
                lc_code: 'LC编号',
                sap_return_status: 'SAP返回状态',
                amount_difference: '金额差异',
                invoice_date: '发票日期',
                invoice_import_date: '发票导入日期',
                sap_voucher_code: 'SAP凭证编码',
                unbinding: '解绑',
                no_unbinding_lc: '无可解绑LC',
                sub_order_no: '子订单编号',
                submit_sap: '是否提交SAP',
                binding: '绑定',
                case_no: '箱号：',
                outbound_delivery: '外向交货单',
                document_type: '单证类型',
                quantity_difference: '数量差异',
                accessory_code: '配件代码',
                accessory_name: '配件名称',
                sales_order_information: '销售单信息',
                total_lines_of_accessories: '配件总行数：',
                total_number_of_accessories: '配件总数量：',
                total_amount: '总金额：',
                amount_of_accessories: '配件金额：',
                premium: '保费：',
                volume: '包装总体积(m³)：',
                total_gross_weight: '包装总毛重：',
                weight_of_package: '包装总净重：',
                container_no: '集装箱号',
                actually_delivered: '实发配件代码',
                order_parts_code: '订货配件代码',
                shipment_quantity: '发货数量',
                proforma_invoice: '形式发票号',
                customs_date: '报关年月',
                customs_no: '报关单号',
                net_weight: '净重（kg）',
                rough_weight: '毛重（kg）',
                volumes: '体积(m³)',
                outer_bo_size: '外箱尺寸（长mm）',
                box_size: '外箱尺寸（宽mm）',
                box_height: '外箱尺寸（高mm）',
                modification_date: '修改日期',
                modification_by: '修改人',
                before_modification: '修改前运费',
                modified_freight: '修改后运费',
                sales_slip: '销售单',
                modification_record: '运费修改记录',
                recommended_freight: '空运限制件',
                document_status: '单证状态',
                not_created: '未创建',
                contract_created_successfully: '合同创建成功',
                introverted_creation_successful: '内向创建成功',
                outgoing_creation_successful: '外向创建成功',
                xingfa_creation_successful: '形发创建成功',
                should_notify_customs_declaration: '是否通知报关',
                original_packing_list_number: '原箱单号',
                formal_invoice_creation_date: '形式发票创建日期',
                should_customs_declaration: '是否通知报关',
                formal_invoice_date: '形式发票日期',
                first_download_date_of_CI: '首次下载CI日期',
                port_of_departure: '起运港',
                import__packing_list: '导入MG&IM箱单',
                acid_maintenance: 'ACID维护',
                export_sc: '导出SC',
                proxy_pi_import: '代理PI导入',
                proxy_ci_export: '代理CI导出',
                cancel_lcl: '取消拼箱',
                generate_price_list: '生成价格清单',
                change_of_departure_port: '起运港改运',
                amount__auto_parts: '配件金额（FOB）',
                calculation_freight: '重算运费',
                update_pack_no: '修改箱单号',
                confirm_export: '订单类型不同，是否确认导出合并CI&PL文件?',
                dangerous_goods_surcharge_tip: '是否添加危险品附加费',
                tips: '提示',
            },
            price: {
                DNNumber: 'DN号',
                deliveryDate: '交货日期',
                purchasePrice: '采购单价',
                priceForDelivery: '乘用车交货单价',
                brand: '品牌',
                part_no: '配件代码',
                part_name: '配件名称',
                currency: '币种',
                fob_unit_price: 'FOB单价',
                brand_and_accessory: '查询时品牌、配件代码必填',
            },
            payableList: {
                smil_sap_order_no: '发票号',
                ci_date: '形发生成日期',
                status: '付款状态',
                bl_date: 'ATD',
                overdue_date: '逾期日期',
                smil_sap_contract_no: '合同号',
                category: '品牌',
                ci_price: '发票总金额',
                mark_payment: '标记付款',
                remittance_ref: '银行付款凭证号',
                import_payment_list: '导入付款清单',
            },
            lcManagement: {
                lc_no: '信用证编号',
                sap_no: 'SAP编号',
                review_status: '审核状态',
                pending_approval: '待审核',
                approved: '审核通过',
                returned: '审核退回',
                create_date: '创建日期',
                valid_or_not: '是否有效',
                dt_code: 'DT代码',
                date: '日期',
                amount_status: '款项状态',
                for_confirmation: '待确认',
                returneds: '已退回',
                recorded: '已入账',
                expended: '已支出',
                amount_type: '资金类型',
                new_lc: '新增LC',
                lc_amount: 'LC资金',
                expired_lc: 'LC过期',
                packing_list: '箱单号',
                add_lc: '新增LC',
                lc_renewal: 'LC续期',
                lc_recharge: 'LC充值',
                edit: '编辑',
                export: '导出',
                review: '审核',
                detail: '明细',
                approveds: '通过',
                return: '退回',
                save: '保存',
                cancel: '取消',
                upload_files: '上传附件',
                start_date: '有效开始日期',
                end_date: '有效截至日期',
                updated_date: '更新日期',
                review_type: '审核类型',
                review_comments: '审核意见',
                review_date: '审核日期',
                recharge_amount: '充值金额',
                original_end_date: '原有效截至日期',
                new_end_date: '新有效截至日期',
                file: '附件',
                lc_balance: 'LC金额结余',
                lc_total_amount: 'LC总金额结余',
                lc_file: '信用证附件',
                new_lc_review: '新增LC审核',
                lc_renewal_review: 'LC续期审核',
                lc_recharge_review: 'LC充值审核',
                add_lcs: '新增LC',
                note_documents: ' 注：支持上传EXCEL、PDF、WORD、PNG、JPG、JPGE, RAR, ZIP 文档',
                deduction_of_lc_amount: '一键生成单证扣除',
                refund_due_to_documents_delete: '一键删除单证退回',
                lc_balances: 'LC余额',
                currency: '币种',
                lc_balan: 'LC金额',
                file_upload_exception: '文件上传异常',
                lc_type: 'LC类型',
            },
            dtOrderReview: {
                approved: '通过',
                returned: '退回',
                batch_approva: '确定批量审核通过?',
                confirmed: '通过',
                cancel: '取消',
                submitted_date: '提交日期',
                transportation_mode: '运输方式',
                returned_reason: '退回原因',
            },
            inventoryQuery: {
                inventory_Query: 'OEM库存查询',
                part_no: '配件代码',
                inventory_existing: '是否有库存',
                brand: '品牌',
                part_name: '配件中文名称',
                part_name_en: '配件英文名称',
            },
        },
        notice: {
            query: {
                announcement_number: '公告编号',
                announcement_name: '公告名称',
                announcement_type: '公告类型',
                release_date: '发布日期',
                announcement_information: '公告信息',
                announcement_status: '公告状态',
                does_attachments: '是否包含附件',
                release: '发布',
                check: '查看',
                protocol: '附件',
                delay_remarks: '延期备注',
                delay_status: '延期状态',
                submission_date: '订单上报日期',
            },
        },
        document: {
            document: '文档',
            name: '名称',
            sort: '排序',
            directory: '文档目录',
            parent_directory: '上级目录',
            new_directory: '新建目录',
            edit_directory: '编辑目录',
            primary_directory: '一级目录',
            document_title: '文档标题',
            vehicle_platform: '车型平台',
            status: '状态',
            release_status: '发布状态',
            create_time: '创建时间',
            secondary_directory: '二级目录',
            model: '车型',
            attachment_name: '附件名称',
            publishing_department: '发布部门',
            publisher: '发布人',
            person_in_charge: '负责人',
            remarks: '备注',
            publish_objects: '发布对象',
            publish_mode: '发布模式',
            whether_the_document_is_downloadable: '文档是否可下载',
            document_information: '文档信息',
            document_tag: '文档标签',
            operation_record: '操作记录',
            operation_details: '操作明细',
            operating_time: '操作时间',
            operator: '操作人',
            to_be_released: '待发布',
            published: '已发布',
            voided: '已作废',
        },
        announcement: {
            title: '标题',
            publishing_department: '发布部门',
            status: '状态',
            release_Date: '发布日期',
            serialNo: '序号',
            publisher: '发布人',
            file_Name: '附件名称',
            details: '详情',
            void: '作废',
            information_detail: '公告信息',
            notification_Title: '通知标题',
            publish_Objects: '发布对象',
            publish_Mode: '发布模式',
            bulletin_id: '公告id',
            publish_Content: '发布内容',
            preview: '预览',
            attachmentUpload: '附件上传',
            supportedFormats: '支持格式',
            have: '共有',
            NoticeWaitingToBeRead: '条待阅通知',
            released: '已发布',
            voided: '已作废',
        },
        basic: {
            accessories: {
                masterdata: {
                    english_smpv: 'SMPV英文名称',
                    accessory_code: '配件代码',
                    accessory_name: '配件名称',
                    accessory_name_zh: '配件中文名称',
                    accessory_name_en: '配件英文名称',
                    accessory_enm: '配件英文描述',
                    brand: '品牌',
                    sales_area: '销售区域',
                    create_data: '创建日期',
                    is_purchase: '是否采购',
                    purchase_attribute: '采购属性',
                    is_marketable: '是否可销售',
                    supplier_code: '供应商编码',
                    supplier_name: '供应商名称',
                    country_origin: '原产地',
                    is_transportation: '是否禁空运',
                    is_transportations: '禁空运',
                    magnetic_inspection: '磁检',
                    // special_procurement_cycle: '特殊采购周期',
                    material: 'Material',
                    is_max_overbooking: '是否控制最大下单量',
                    max_overbooking: '允许最大下单量',
                    sap_code: 'SAP Code',
                    unit: '配件单位',
                    accessories_edit: '配件编辑',
                    accessories_add: '配件新增',
                    accessories_detailed: '配件明细',
                    special_procurement_cycle: '特殊采购周期',
                    name_of_customs_declaration_materials: '报关物料名称',
                    customized_form: '定制类订单',
                    customs_declaration_unit: '报关英文单位',
                    remarks: '备注',
                    customs_code: '海关编码',
                    license_material_name: '许可证物料名称',
                    create_by: '创建人',
                    company: '公司',
                    declare_ele: '规格',
                    base_cal_unit: '配件单位',
                    base_cal_unit_cn: '报关中文单位',
                    update_by: '修改人',
                    update_time: '修改日期',
                    accessories_picture: '配件图片',
                    custom_accessories: '定制附件',
                    declaration_elements: '申报要素',
                    transportation: '禁止空运',
                    accessories_selection: '配件选择',
                    enable_or_not: '是否启用',
                    supplier_selection: '供应商选择',
                    whether_glass: '是否玻璃',
                },
                orderInvoicing: {
                    partCode: '配件代码',
                    brand: '品牌',
                    baseQuantity: '基本数量',
                    baseUnit: '基本单位',
                    transferQuantity: '转换数量',
                    transferUnit: '转换单位',
                    createBy: '创建人',
                    createTime: '创建时间',
                    orderInvoicing_new: '新增',
                    orderInvoicing_edit: '编辑',
                },
                replace: {
                    no: '序号',
                    group_no: '组编号',
                    item_no: '项目编号',
                    leading_products: '前趋产品',
                    description_of_leading_products: '前趋产品描述',
                    subsequent_products: '后继产品',
                    subsequent_product_description: '后继产品描述',
                    substitutive_relationship: '替代关系',
                    exhaustion_strategy: '耗尽策略',
                    depletion_date: '耗尽日期',
                    effective_date: '生效日期',
                    creation_date: '创建日期',
                    newly_added: '新增',
                    newly_edit: '编辑',
                },
                billMaterials: {
                    dt_name: 'DT', // DT名称
                    part_code: '配件代码', // 零件代码
                    part_name_cn: '配件中文名称', // 中文名称
                    part_name_en: '配件英文名称', // 英文名称
                    apply_time: '申请日期', // 申请日期
                    brand: '品牌', // 品牌
                },
                mxreplace: {
                    group_no: '组编号',
                    project_no: '项目编号',
                    part_code: '配件代码',
                    part_name_cn: '配件中文名称',
                    new_part_code: '替换件配件代码',
                    new_part_name_cn: '替换件配件中文名称',
                    effective_time: '生效日期',
                    stock_deal: '库存处理',
                    create_time: '创建日期',
                    replace_relate: '替换关系',
                    brand: '品牌',
                    replace_remarks: '替换备注',
                    newly_edit: '编辑', // edit
                    part_codes: '配件编码',
                    new_part_codes: '替换配件编码',
                    new_part_name_cns: '替换配件中文名称',
                    stock_deals: '库存处理建议',
                },
                sap: {
                    sap_part_id: '主键', // 主键
                    part_id: '零件id', // 零件id
                    brand: '品牌', // 项目编号
                    part_code: '旧物料号', // 零件代码
                    material_type: 'SAP物料类型', // ZTG2/SAP物料类型
                    customer_declare_material_name: '报关物料名称', // 报关物料名称
                    material_name_en: '英文物料名称', // 英文物料名称
                    // brand: string; // 品牌
                    hs_code: 'HS编码', // HS编码
                    customer_declare_licence: '许可证物料名称', // 许可证物料名称
                    base_cal_unit: '基本计量单位', // 基本计量单位
                    sap_code: 'SAP物料号', // SAP物料号
                    sap_codes: 'SAP物料类型', // SAP物料号
                    gross_weight: '毛重', // 毛重
                    declare_ele: '申报要素', // 申报要素
                    net_weight: '净重', // 净重
                    volume: '体积(m³)', // 体积
                    specs: '规格', // 规格
                    danger_attr: '产品属性2（危险品）', // 产品属性2(危险品)
                    abroad_danger_attr: '产品属性3（国外危险品标识）', // 产品属性3(国外危险品标识)
                    status: '创建状态', // 状态0-草稿，1-进行中，2-已完成
                    create_by: '创建人', // 创建人
                    create_time: '创建日期',
                    update_by: '修改人', // 创建人
                    update_time: '修改时间',
                    sap_edit: '编辑',
                    sap_add: '新增',
                    error_reason: '返回消息',
                    sap_status: 'SAP返回状态',
                    part_name_cn: '中文物料描述',
                    sale_tax_class: '销售税分类',
                    error_reasons: 'SAP物料类型',
                    // sap_status: 'sap返回状态',
                },
                coo: {
                    country_id: '国家',
                    brand: '品牌',
                    part_code: '配件代码',
                    part_name_cn: '配件名称',
                    origin_country: '原产地',
                    create_by: '创建人',
                    create_time: '创建日期',
                    coo_add: '新增',
                    coo_edit: '编辑',
                    accessories_selection: '配件选择',
                },
                sapModify: {
                    gds_material_number: 'GDS物料号',
                    sap_material_number: 'SAP物料号',
                    chinese_name_of_material_zh: '物料中文名ZH',
                    english_name_of_material_en: '物料英文名EN',
                    material_declaration_name_z1: '物料报关名Z1',
                    declaration_elements: '申报要素',
                    classification_proposal_no: '归类建议书号',
                    period_of_validity_of_classification: '归类有效期',
                    tax_classification_code: '税收分类编码',
                    factory: '工厂',
                    status: '状态',
                    sap_status: 'SAP返回状态',
                    commodity_import_code_number: '商品/进口代码编号',
                    sap_modify_edit: '编辑',
                    sap_modify_add: '新增',
                },
            },
            sales: {
                weight_ladder: '重量阶梯',
                country: '国家',
                port: '港口',
                portCode: '港口代码',
                portUS: '港口英文名称',
                portCN: '港口中文名称',
                EffectiveDate: '生效日期',
                ExpirationDate: '失效日期',
                oldEffectiveTime: '原生效日期',
                oldExpireTime: '原失效日期',
                newEffectiveTime: '新生效日期',
                newExpireTime: '新失效日期',
                freight: '运费系数',
                KG: 'KG',
                KGabove: 'KG以上',
                express: '快递区域',
                general: '普货',
                danger: '危险品',
                GP20: '20GP单价(美元)',
                GP40: '40GP单价(美元)',
                hq40: '40HQ单价(美元)',
                region: '快递区域',
                currencyCode: '货币码',
                description: '描述',
                MinimumUnit: '最小单位',
                ChineseDescription: '中文描述',
                symbol: '符号',
                updated_date: '上次更新日期',
                price_benchmark: '价格基准',
                part_description: '配件描述',
                excluding_tax: '不含税采购价',
                tax: '含税采购价',
                price_including_tax: '国内含税销价',
                seller: '销售方',
                selling_party: '售达方',
                price: '价格',
                sales_ratio: '销售比例(%)',
                cifShare: {
                    trade_clause: '贸易条款',
                    order_type: '订单类型',
                    type_of_shipping: '运输方式',
                    dt: 'DT',
                    last_modified_date: '最后修改日期',
                    creation_date: '创建日期',
                    sales_price_proportion: '销售价格比例(%)',
                    seller: '销售方',
                    dt_choose: 'DT选择',
                    dt_code: 'DT代码',
                    dt_name: 'DT名称',
                    country: '国家',
                },
                cifSingle: {
                    trade_clause: '贸易条款',
                    accessory_type: '配件类型',
                    type_of_shipping: '运输方式',
                    dt: 'DT',
                    freight_coefficient: '运费系数(%)',
                    premium_coefficient: '保险费系数(%)',
                    air_freight0: '空运(0-100KG)',
                    air_freight100: '空运(100-500KG)',
                    air_freight500: '空运(500KG+)',
                    minimum_air_freight_charge: '空运最低收费',
                    dangerous_goods_freight: '危险品运费',
                    dangerous_goods_minimum_freight: '危险品运费最小收费',
                    dangerous_goods_ITEM_freight: '危险品ITEM级别收费',
                    dangerous_goods_qty_freight: '危险品qty级别收费',
                    dt_choose: 'DT选择',
                    dt_code: 'DT代码',
                    dt_name: 'DT名称',
                },
                triesLimit: {
                    dt: 'DT',
                    frequency_limit: '次数限制',
                    price_ratio: '价格比例(%)',
                    seller: '销售方',
                    brand: '品牌',
                    dt_choose: 'DT选择',
                    dt_code: 'DT代码',
                    dt_name: 'DT名称',
                },
                dcs: {
                    partial_transmission: '部分传递',
                    full_transmission: '全量传递',
                    download_template: '下载模板',
                    upload_Excel: '上传Excel',
                    upload_files: '上传文件',
                    no_files_selected: '未选择任何文件',
                    General_generation: '总代',
                    DCS_price_delivery_price: 'DCS价格传递（FOB）价',
                },
            },
            customer: {
                fundPool: {
                    dt_code: 'DT代码',
                    dt_name: 'DT名称',
                    dt_short_name: 'DT简称',
                    currency: '币种',
                    account_amount: '账户金额',
                    freeze_amount: '冻结金额',
                    date: '日期',
                    fund_status: '款项状态',
                    fund_type: '资金类型',
                    payment_number: '付款凭证号',
                    credit_number: '信用证编号',
                    suborder_number: '子订单编号',
                    container_number: '箱单号',
                    credit_validity_date: '信用证有效期',
                    recorded: '入账/支出',
                    disbursement_money: '支出金额',
                    wait_recorded_money: '待入账金额',
                    recorded_money: '入账金额',
                    service_charge: '手续费',
                    account_balance: '账户金额结余',
                    freeze_balance: '冻结结余',
                    credit_picture: '信用证图片',
                    upload_list: 'Upload List',
                    remark: '备注',
                    dt_payment_confirm: 'DT付款确认',
                    credit_confirm: '信用证确认',
                    credit_money: '信用证金额',
                    arrival_amount: '到账金额',
                    dt_selection: 'DT选择',
                },
                customer: {
                    dt_code: 'DT代码',
                    dt_name: 'DT名称',
                    dt_short_name: 'DT简称',
                    oem_code: 'OEM代码',
                    country: '国家',
                    sap_code: 'SAP代码',
                    brand: '品牌',
                    beneficiary_name: '受益人名称',
                    beneficiary_address: '受益人地址',
                    VAT: 'VAT（%）',
                    Name_of_bank_of_deposit: '开户银行名称',
                    VAT_number: 'VAT Number',
                    bank_address: '银行地址',
                    SWIFT_CODE: 'SWIFT CODE',
                    bank_account: '银行账号',
                    Agent_bank_address: '代理银行地址',
                    Agent_bank_account: '代理银行账号',
                    Agent_bank_name: '代理银行名称',
                    IBAN: 'IBAN',
                    Agent_bank_iban: '代理银行的iban',
                    Agent_bank_SWIFT_Code: '代理银行的SWIFT Code',
                    Bank: 'BANK',
                    inherit_general_generation: '继承总代',
                    money: '货币',
                    A_24_hour_hotline: '24小时热线电话',
                    facsimile: '传真',
                    telephone: '电话',
                    local_country: '所在国家',
                    local_city: '所在城市',
                    local_area: '所在区域',
                    Postal_code: '邮政编码',
                    email_address: '电子邮箱',
                    Affiliated_brand: '所属品牌',
                    Company_homepage: '公司主页',
                    Discount_rate_for_parts_sales: '配件销售折扣率',
                    Company_address: '公司地址',
                    Method_of_settlement: '结算方式',
                    Sales_territory: '销售区域',
                    Express_freight_area: '快递运费区域',
                    Selling_party: '售达方',
                    Address_of_the_selling_party: '售达方地址',
                    Loan_agency_information: '货代信息',
                    Payment_terms: '付款条件',
                    Account_period: '账期',
                    supplier: '供货方',
                    Shipping_address: '海运地址',
                    Contact_name: '联系人姓名',
                    File_delivery_address: '文件收货地址',
                    Air_address: '空运地址',
                    Express_address: '快递地址',
                    organization: '组织',
                    Trade_clause1: '贸易条款1',
                    Trade_clause2: '贸易条款2',
                    beneficiary: 'Beneficiary',
                    fmc: 'FMC',
                    Swift_Code: 'Swift Code',
                    USD_Account: 'USD Account',
                    EUR_Account: 'EUR Account',
                    RMB_Account: 'RMB Account',
                    Tax_number: '税号',
                    MG_order_clerk: 'MG订单员',
                    MAXUS_order_clerk: 'MAXUS订单员',
                    Method_of_payment: '支付方式',
                    Market_type: '市场类型',
                    MX_business_model: 'MX业务模式',
                    label: '标签',
                    Air_freight_rate: '空运运费比例（%）',
                    Ocean_freight_rate: '海运运费比例（%）',
                    Express_rate: '快递运费比例（%）',
                    Document_number_prefix: '单据号前缀',
                    TT_settlement_term: 'TT支付条款',
                    LC_settlement_term: 'LC支付条款',
                    name: '名称',
                    Contact_person: '联系人',
                    Contact_number: '联系人电话',
                    address: '地址',
                    city: '城市',
                    Zip_code: '邮编',
                    sap_consumer_query: 'SAP客户查询',
                    customer_code: '客户代码',
                    customer_name: '客户名称',
                    sales_organization: '销售组织',
                    abbreviation: '客户简称',
                    organization_name: '组织名称',
                    customer_information: '客户信息',
                    delivered_by: '送达方',
                },
            },
            sort: {
                model: {
                    type_code: '车型分类代码',
                    brand: '品牌',
                    parts_code: '配件代码',
                    parts_name: '配件名称',
                    save_add: '保存并新建',
                },
                code: {
                    type_code: '车型分类代码',
                    model_grade: '车型等级',
                    save_add: '保存并新建',
                },
                maxmaterial: {
                    category_code: '物料大类',
                    // category_codes: '定价大类',
                    category_desc: '描述',
                    maxmaterial_add: '新增',
                    maxmaterial_edit: '编辑',
                },
                minmaterial: {
                    no_air: '禁空运',
                    category_code: '物料小类代码',
                    category_desc: '物料小类描述',
                    big_material_category_id: '物料大类',
                    pack_alone: '是否单独包装',
                    minmaterial_add: '新增',
                    minmaterial_edit: '编辑',
                    material_category_selection: '物料小类选择',
                },
                marialMaint: {
                    small_material_category_id: '物料小类代码', // 物料小类代码
                    brand: '品牌',
                    create_time: '创建日期',
                    create_by: '创建人',
                    part_id: '配件代码', // 配件代码
                    marial_maint_add: '新增',
                    marial_maint_edit: '编辑',
                },
            },
            packing: {
                information: {
                    transport_package_No: '运输包装号',
                    classification: '分类',
                    name: '名称',
                    purpose: '用途',
                    length: '长(mm)',
                    width: '宽(mm)',
                    height: '高(mm)',
                    volume: '体积(CBM)',
                    specification_requirements: '规格要求',
                    brand: '品牌',
                    creation_date: '创建日期',
                    created_by: '创建人',
                    purchasing_unit: '采购单位',
                },
                volume: {
                    volume_factor: '运输包装体积系数',
                    shipping_type: '运输方式',
                    brand: '品牌',
                    creation_date: '创建日期',
                    created_by: '创建人',
                },
                cmaintain: {
                    packing_type_code: '包装类型代码',
                    english_description: '英文描述',
                },
                mpq: {
                    transport: '运输方式',
                    category_code: '小类代码',
                },
                sap: {
                    pack_material_code: '包装物料号',
                    chinese_desc: '中文名称',
                    english_desc: '英文名称',
                    sap_add: '新增',
                    sap_edit: '编辑',
                },
                minmaterial: {
                    small_material_category_code: '物料小料代码',
                    small_material_category_desc: '物料小料描述',
                    special_compute: '是否特殊计算',
                    create_time: '创建日期',
                    create_by: '创建人',
                    small_code: '物料小类',
                    minmaterial_add: '新增',
                    minmaterial_edit: '编辑',
                },
                maintain: {
                    package_volume: '包装体积（m³）',
                    part_code: '配件代码',
                    brand: '品牌',
                    minimum_number_of_packages: '最小包装数',
                    long: '长',
                    width: '宽',
                    height: '高',
                    gross_weight: '毛重',
                    net_weight: '净重',
                    creation_date: '创建日期',
                    create_by: '创建人',
                    revision_date: '修改日期',
                    revision_by: '修改人',
                    package_type: '包装类型',
                    unit_of_length: '长度单位',
                    unit_of_weight: '重量单位',
                    package_plan: '包装方案',
                },
                dangerInfo: {
                    accessory_code: '配件代码',
                    nam_accessories: '配件英文名称',
                    accessories_cn: '配件中文名称',
                    brand: '品牌',
                    bonfire_experiment: '篝火实验号',
                    bonfire_experiment_cn: '篝火实验中文品名',
                    bonfire_experiment_en: '篝火实验英文品名',
                    dangerous_goods_supplier: '危险品供应商',
                    length: '长（mm）',
                    width: '宽（mm）',
                    height: '高（mm）',
                    packing_type: '包装类型',
                },
            },
            customs: {
                hscode: {
                    hs_code: '海关代码', // 海关代码
                    hscode_unit: 'L/KG', // L/KG
                    is_inspect: '是否商检', // 是否商检，Y-是，N-否
                    tax_refund_rate: '退税率（%）', // 退税率
                    supervise_condition: '监管条件', // 监管条件
                    company: '公司', // 公司
                    country_id: '国家', // 国家
                    create_time: '创建时间',
                    hscode_add: '新增',
                    hscode_edit: '修改',
                },
                export: {
                    brand: '品牌', // 品牌
                    part_code: '配件代码', // 配件代码
                    conutry_code: '国家代码', // 国家代码
                    hscode: '海关编码', // 海关编码
                    form: 'FORM-E', // FORM-E
                    tariff: 'TARIFF', // TARIFF
                },
                fluid: {
                    brand: '品牌',
                    part_id: '配件代码',
                    part_id_name: '配件名称',
                    fluid_add: '新增',
                },
                danger: {
                    company: '公司', // 公司
                    ship_type: '运输方式', // 运输方式
                    part_code: '配件代码', // 配件代码
                    brand: '品牌',
                    danger_type: '危险品类型', // 危险品类型
                    danger_category: '危险品分类', // 危险品分类
                    chinese_part_name: '配件中文名称',
                    english_part_name: '配件英文名称',
                    danger_add: '新增',
                    danger_edit: '编辑',
                },
                choren: {
                    chinese_unit: '中文单位',
                    english_unit: '英文单位',
                    create_by: '创建人',
                    create_time: '创建日期',
                    choren_add: '新增',
                    choren_edit: '编辑',
                },
                maintain: {
                    dt: 'DT',
                    material_no: '物料号',
                    brand: '品牌',
                    customs_code: '海关编码',
                    form_e_mark: 'Form E Mark',
                    created_on: '创建日期',
                    created_by: '创建人',
                    maintain_add: '新增',
                    maintain_edit: '编辑',
                },
                chemicali: {
                    company: '公司',
                    material_no: '物料号',
                    brand: '品牌',
                    license_or_not: '是否许可证',
                    chemicali_add: '新增',
                    chemicali_edit: '编辑',
                },
            },
            salesl: {
                fixedRate: {
                    local_currency: '本币',
                    name_of_local_currency: '本币名称',
                    seller: '销售方',
                    fixed_rate: '固定汇率',
                    freight_exchange_rate: '是否运费汇率',
                    settlement_currency: '结算货币',
                    name_of_settlemen_currency: '结算货币名称',
                    dt: 'DT',
                    brand: '品牌',
                    effective_date: '生效日期',
                    expiration_date: '失效日期',
                    created_on: '创建日期',
                    fixedRate_add: '新增',
                    fixedRate_edit: '修改',
                    fixedRate_select: '选择DT后，品牌不为空',
                },
                transport: {
                    dt: 'DT',
                    brand: '品牌',
                    type_of_shipping: '运输方式',
                    trade_terms: '贸易条款',
                    created_on: '创建日期',
                    transport_add: '新增',
                    transport_edit: '修改',
                },
                purchaseConfirm: {
                    accessory_code: '配件代码',
                    effective_date: '生效日期',
                    expiration_date: '失效日期',
                    accessory_description: '配件描述',
                    purchase_price: '不含税采购价',
                    tax_rate: '税率',
                    currency: '币种',
                    corporation: '公司',
                    created_on: '创建日期',
                },
            },
        },
        order: {
            claimReport: {
                parts_no: '索赔申请单号',
                packing_list_no: '箱单号',
                submit_date: '提交日期',
                suborder_number: '子订单号',
                smpv_number: '乘用车订单号',
                part_code: '配件代码',
                claim_type: '索赔类型',
                current_process_status: '索赔单状态',
                dt_name: 'DT名称',
                creation_date: '创建日期',
                claim_total_price: '索赔单金额',
                times_of_claims: '索赔次数',
                part_name: '配件英文名称',
                shipment_qty: '发货数量',
                received_qty: '实收数量',
                claim_qty: '索赔数量',
                approved_qty: '批准数量',
                sale_price: '索赔单价(采购金额)',
                total_price: '索赔金额(小计)',
                problem_description: '索赔原因',
                approved_date: 'SMIL审核时间',
                audit_result: 'SMIL审核结果',
                smil_audit_description: 'SMIL审核描述',
                oem_approved_date: 'OEM审核时间',
                ome_audit_result: 'OEM审核结果',
                oem_audit_description: 'OEM审核描述',
                scrap_image_submission_time: '报废图片提交时间',
                image_audit_time: 'SMIL报废图片审核时间',
                image_audit_results: 'SMIL报废审核结果',
                scrap_time: 'OEM报废图片审核时间',
                oem_image_audit_results: 'OEM报废审核结果',
                current_statuss: '当前索赔处理状态',
                update_date: '更新时间',
                tac_review_comments: 'TAC审核意见',
                tac_approved_date: 'TAC审核时间',
                tac_audit_result: 'TAC审核结果',
                tac_audit_description: 'TAC审核描述',
                tac_image_audit_time: 'TAC报废图片审核时间',
                tac_image_audit_results: 'TAC报废审核结果',
            },
            claim: {
                cannot_be_empty: '不能为空',
                cannot_be: '不能为0',
                creater: '创建人',
                brand: '品牌',
                claimAmountAll: '索赔总金额',
                claimAmounts: '索赔客户邮箱',
                creationDate: '创建日期属于',
                currentState: '当前状态',
                claimNumber: '索赔次数',
                claimName: '名称',
                claimNameCN: '中文名称',
                claimNameUS: '英文名称',
                Application: '配件索赔申请单号',
                orderNumber: '订单号',
                amount: '请输入金额',
                frequency: '请输入次数',
                applicationNumber: '申请单号',
                creationDateIn: '创建日期',
                new: '新建',
                applications: '索赔申请单列表',
                creationTime: '创建时间',
                money: '货币',
                containerNumber: '箱单号',
                pack_noCode: '箱单号代码',
                suborder: '子订单号',
                PartNumber: '配件编号',
                claimType: '索赔类型',
                orderQuantity: '订单数量',
                receivedQuantity: '实收数量',
                claimNumbers: '索赔数量',
                approvedQuantity: '批准数量',
                purchaseAmount: '采购金额',
                subtotal: '小计',
                scrapPhotos: '报废图片',
                accessoriesPhotos: '配件图片',
                accessoriesLabel: '配件标签',
                accessoriesPackagingPhotos: '配件包装图片',
                transportation: '运输包装图片',
                packingList: '装箱单图片',
                claimReason: '索赔原因',
                smil_review_comments: 'SMIL审核意见',
                oem_review_comments: 'OEM审核意见',
                DTNumber: 'DT编号',
                claimMessage: '索赔信息',
                Circulationadvice: '流转意见',
                General_generation_name: '总代名称',
                claim_order: '索赔单号',
                audit_result: '审核结果',
                OEMResult: 'OEM审核结果',
                responsible: '责任方',
                modification_date: '修改日期',
                updated_date: '更新日期',
                General_agent: '总代',
                ATA_time: 'ATA设置天数',
                claimAmount: '索赔金额',
                pack_choose: '箱单号选择',
                suborder_choose: '子订单号选择',
                PartNumber_choose: '配件编号选择',
                request_pack: '请勾选箱单号',
                request_suborder: '请勾选子订单号',
                request_PartNumber: '请勾选配件编号',
                request_Claim: '请勾选索赔零件行',
                request_ATA: '没有ata时间，无法索赔',
                information: '基本信息',
                completionStatus: '索赔完成',
                Handlingsuggestion: '处理意见',
                picture: '图片',
                greater: '大于或等于',
                less: '小于或等于',
                week: '本周',
                month: '本月',
                quarter: '本季',
                year: '本年',
                processed_by: '处理人',
                time_submitted: '提交时间',
                recipient: '接收人:',
            },
            report: {
                logisticsEfficiency: {
                    dt_code: 'DT代码',
                    dt_name: 'DT名称',
                    order_month: '订单月份',
                    order_quantity: '订单量',
                    total_order_amount: '订单总金额',
                    packaging_timeliness: '包装时效',
                    maritime_transport_general_cargo: '海运-普货',
                    air_transport: '空运',
                    sea_transportation: '海运',
                    express_delivery: '快递',
                    zh_order_quantity: '准点订单量',
                    punctuality_rate: '准点率',
                    maritime_transport_dangerous_goods: '海运-危险品',
                    otd_timeliness: 'OTD时效',
                    transport_prescription: '运输时效',
                    lines: '订单行数',
                    amount: '订单金额',
                },
                abnormalOrder: {
                    Overexpectationprocessing: '超期待处理',
                    Overduehasbeenprocessed: '超期已处理',
                    packing: '包装',
                    OTD: 'OTD',
                    transport: '运输',
                    Orderlinenumber: '订单行数',
                    Orderlinenumberall: '订单总行数',
                    Anomalyrate: '异常率',
                },
            },
            distributionManagement: {
                list_information: '箱单信息',
                container_scheme: '集装箱方案',
                logistics_registration: '物流登记',
                dt_code: 'DT代码',
                packing_list: '箱单号',
                date_warehousing: '进仓日期',
                packaging_completed: '包装完成',
                name_voyage: '船名/航次/快递',
                other_expenses: '其他费用',
                bill_lading: '提单号',
                print_shipping_mark: '打印唛头',
                warehousing_confirmation: '进仓确认',
                fill_other_expenses: '填写其他费用',
                type_shipping: '运输方式',
                shipping_company: '船公司',
                ships_name: '船名',
                voyages_flights: '航次',
                ship_number: '航班号',
                customs_no: '关单号',
                return_lading: '提单返回方式',
                expected_date: '预计返回日期',
                actual_date: '实际返回日期',
                remark: '备注',
                container_no: '集装箱号',
                container_type: '集装箱类型',
                customs_declaration: '报关单号',
                container_nos: '集装箱编号',
                reason_modification: '修改原因',
                warehouse_release_date: '出仓日期',
            },
            management: order.claim.management.zhCN,
        },
        tech: {
            quality: {
                problemTracking: {
                    customs_declaration_unit: '报关单位',
                    theme: '主题',
                    model: '型号',
                    problem_state: '问题状态',
                    TAC_submit_date: 'TAC提交日期',
                    level: '级别',
                    type: '类型',
                    vehicle_involved: '涉及车辆',
                    activity_code: '活动代码',
                    activity_name: '活动名称',
                    connected_activities: '关联活动',
                    system_problems: '系统问题',
                    quantity: '数量',
                    engine_type: '发动机类型',
                    gearbox_type: '变速箱类型',
                    country: '国家',
                    region: '地区',
                    date_of_feedback_to_QA: '反馈到QA日期',
                    required_part_return_date: '要求零件返回日期',
                    date_of_parts_delivery_to_QA: '零件交付到QA日期',
                    solution_release_date: '解决方案发布日期',
                    solution_target_date: '解决方案目标日期',
                    end_date: '结束日期',
                    difference: '差异',
                    QA_weekday: 'QA工作日',
                    fault_description: '故障描述',
                    reason: '原因',
                    solution: '解决方案',
                    breakpoint: '断点VIN/CSN/En',
                    process: '过程',
                    details: '详情',
                    technical_document_attachments: '技术文档附件',
                },
                recallActivity: {
                    activity_code: '活动代码',
                    activity_name: '活动名称',
                    responsible_party: '责任方',
                    release_date: '发布日期',
                    data_sources: '数据来源',
                    recall_campaign_type: '召回活动类型',
                    service_activity_code: '服务活动代码',
                    service_activity_name: '服务活动名称',
                    buyout_company: '买断公司',
                    audit_company: '审核公司',
                    currency: '币种',
                    money: '金额',
                    start_date: '开始日期',
                    end_date: '结束日期',
                    failure_mode: '失效模式',
                    activity_programme: '活动方案',
                    improve_handling: '改善措置',
                    activity_information: '活动信息',
                    vehicle_involved_list: '涉及车辆列表',
                    template_file: '模板文件',
                    browse: '浏览',
                    download_template: '下载模板',
                    claim_vehicles_list: '索赔发生车辆列表',
                    claim_statistics: '索赔统计',
                    technical_document_attachments: '技术文档附件',
                    vin: 'VIN',
                    is_complete: '是否完成',
                    warranty_claims: '保修索赔',
                    customer: '顾客',
                    no_file_selected: '未选择文件',
                    no_import_file_selected: '未选择导入文件',
                    claim_proportion: '索赔发生比例',
                    claim_settled_amount: '索赔已结算金额',
                    is_claim_again: '是否再索赔',
                    cancel_succeed: '取消成功',
                    build_by_oneself: '自建',
                    system_import: '索赔系统导入',
                    claim_system_import: '索赔系统导入',
                },
            },
            management: {
                newCarDefinition: {
                    activityNumber: '活动编号',
                    activityName: '活动名称',
                    activityStatus: '活动状态',
                    activityDate: '选择活动日期',
                    startDate: '活动开始日期',
                    endDate: '活动结束日期',
                    remarks: '备注',
                    activity_details: '活动详情',
                    activity_addition: '活动新增',
                    confirm_enable: '确认启用',
                    confirm_outage: '确认停用',
                    all: '全部',
                    not_started: '未开始',
                    on_going: '进行中',
                    closed: '已结束',
                },
                newCarlssue: {
                    all: '全部',
                    newly_built: '新建',
                    unpublished: '未发布',
                    validity: '有效性',
                    modelInvolved: '涉及车型',
                    involvedArea: '涉及区域',
                    state: '状态',
                    new_car_reporting_level: '新车上报层级',
                    confirm_release: '确认发布',
                    maintenance: '维护',
                    model_maintenance: '车型维护',
                    area_maintenance: '区域维护',
                    reporting_level: '上报层级',
                    to_be_published: '待发布',
                    published: '已发布',
                    modified_to_be_published: '已修改待发布',
                },
                onSiteSupportHq: {
                    fieldSupportNumber: '现场支持单号',
                    informationSlipNumber: '问询单号',
                    approvalStatus: '审批状态',
                    state: '状态',
                    selectApplicationDate: '选择申请日期',
                    toBeProcessed: '待处理',
                    processed: '已处理',
                    initiated: '已发起',
                    applicationDepartment: '申请部门',
                    applicant: '申请人',
                    reasonApplication: '申请原因',
                    applicationTime: '申请时间',
                    all: '全部',
                    pending_approva: '待审批',
                    agreed: '已同意',
                    rejected: '已拒绝',
                    view: {
                        application_department: '申请部门',
                        application_person: '申请人',
                        responsible_person: '负责人',
                        associated_enquiry_number: '关联问询单号',
                        reason_application: '申请原因',
                        description: '说明(内部申请签字单)',
                        file_name: '文件名',
                        size: '大小(MB)',
                        uploader: '上传人',
                        upload_time: '上传时间',
                        view: '查看',
                        download: '下载',
                        support_department: '支持部门',
                        technical_personnel: '技术人员',
                        contact_information: '联系方式',
                        field_support_information: '现场支持信息',
                        remarks: '备注',
                        apply: '审批',
                        application_information: '申请信息',
                        field_support_personnel: '现场支持人员',
                        approval_record: '审批记录',
                        submit_an_application: '提交申请',
                        refuse: '拒绝',
                        agree: '同意',
                        apply_for: '申请',
                        field_support: '现场支持',
                        reason_for_rejection: '拒绝原因',
                    },
                    dialog: {
                        on_site_application_details: '现场申请详情',
                        on_site_application: '现场申请',
                        reason_for_rejection: '拒绝原因',
                        field_support_application: '现场支持申请',
                        technical_personnel: '技术人员',
                        contact_information: '联系方式',
                        field_support_time: '现场支持时间',
                        note_information: '备注信息',
                    },
                },
                filterHq: {
                    filter_information: '筛选器信息',
                    filter_name: '筛选器名称',
                    user_name: '用户名',
                    creation_time: '创建时间',
                    splicing_description: '拼接描述',
                    enable_or_not: '是否启用',
                    association_symbol: '关联符号',
                    screening_condition: '筛选条件',
                    conditional_value: '条件值',
                    save: '保存',
                    draft: '草稿',
                    no_reply: '未答复',
                    replied: '已答复',
                    closed: '已结案',
                    pre_sales: '售前',
                    after_sales: '售后',
                    to_be_repaired: '待维修',
                    under_maintenance: '维修中',
                    completed: '已完工',
                    not_included: '不包含',
                    and: '并且',
                    perhaps: '或者',
                    not: '否',
                    enable: '是',
                    judgement_logic: '判断逻辑',
                    order_number: '序号',
                    please_add_judgment_logic: '请添加判断逻辑',
                    model_platform_is: '车型平台为',
                    subject_of_the_case_is: '案例主题为',
                    overseas_branches_are: '海外分公司为',
                    overseas_subsidiaries_are: '海外子公司为',
                    general_agent_are: '总代为',
                    fault_symptom_is: '故障现象为',
                    cause_of_the_fault_is: '故障原因为',
                    vehicle_status_is: '车辆状态为',
                    case_type_is: '案例类型为',
                    case_status_is: '案例状态为',
                    all: '全部',
                },
                vehicleInfoHq: {
                    productionTimeList: '生产时间清单',
                    vehicle_configuration_list: '车辆配置清单',
                    accurately_traceable_parts_list: '精确追溯零件清单',
                    order_number: '订单号',
                    order_type: '订单类型',
                    platform: '平台',
                    model_code: '车型代码',
                    date_of_production: '生产日期',
                    certificate_of_conformity: '合格证号',
                    certificate_printing_time: '合格证打印时间',
                    vehicle_material_number: '车辆物料号',
                    description: '描述',
                    time: '时间',
                    feature_ID: '特征ID',
                    feature_description: '特征描述',
                    feature_code: '特征码',
                    eigenvalue_description: '特征值描述',
                    chinese_description: '中文描述',
                    supplier: '供应商',
                    bar_code_number: '条码编号',
                    scanning_time: '扫描时间',
                    key_component_type_code: '关键件类型编码',
                    part_number: '零件号',
                    traceability_number: '追溯号',
                    sub_key_type: '子关键件类型',
                    software: '软件',
                    hardware: '软件',
                    calibration_information: '标定信息',
                    supplier_name: '供应商号',
                    pn: 'PIN码',
                },
                progHistoryHq: {
                    name_of_maintenance_station: '维修站名称',
                    mileage: '里程数',
                    controller_name: '控制器名称',
                    function_name: '功能',
                    time: '时间',
                    function_execution_result: '功能执行结果',
                    channel: '通道',
                    hardware_number: '硬件号',
                    written_software_information: '写入的软件信息',
                    software_information_before_refresh: '刷新前的软件信息',
                    updated_software_information: '刷新后的软件信息',
                },
                salesRecordHq: {
                    material: '物料',
                    sales_order: '销售订单',
                    vehicle_type: '车型',
                    shipping_area: '发运区域',
                    country_of_dispatch: '发运国家',
                    down_date: '下线日期',
                    port_of_destination: '目的港',
                    scheduled_date_of_shipment: '计划发运日期',
                    actual_date_of_shipment: '实际发运日期',
                    current_position: '当前位置',
                    actual_arrival_date: '实际到港日期',
                    retail_date: '零售日期',
                },
                claimHq: {
                    claim_information: '索赔信息',
                    Recall_and_service_activities: '召回和服务活动',
                    marketing_activity_list: '营销活动列表',
                    basic_information: '基础信息',
                    warranty_block: '保修块',
                    repair_block_code: '报修块代码',
                    claim_cycle_list: '索赔周期列表',
                    WTY_Indicates_the_cycle_type: 'WTY周期类型',
                    start_time: '开始时间',
                    end_time: '结束时间',
                    WTY_mileage: 'WTY里程',
                    List_of_claims: '索赔单列表',
                    customer: '顾客',
                    warranty_claim: '保修索赔单',
                    work_order: '工单',
                    date_of_receipt: '接收日期',
                    maintenance_technical_date: '维修技术日期',
                    mileage: '里程',
                    state: '状态',
                    master_working_position: '主工位',
                    manual_code: '人工代码',
                    main_component: '主要部件',
                    part_name: '零件名称',
                    recall_and_service_activities: '召回和服务活动',
                    recall_and_service_activity_code: '召回和服务活动代码',
                    recall_and_service_activity_names: '召回和服务活动名称',
                    brand: '品牌',
                    recall_type_code: '召回类型代码',
                    recall_type_name: '召回类型名称',
                    release_date: '发布日期',
                    closing_date: '关闭日期',
                    complete: '完成',
                    warranty_claim_status: '保修索赔状态',
                    end_of_maintenance_date: '维修结束日期',
                    marketing_activity_code: '营销活动代码',
                    marketing_activity_name: '营销活动名称',
                    vehicle_warranty_period: '车辆质保期',
                    marketing_activities: '市场活动',
                    prohibition_of_claims: '禁止索赔',
                    prohibited_claim_code: '禁止索赔代码',
                    type_of_warranty_period: '质保期类型',
                    repair_end_date: '维修结束日期',
                    reception_date: '接待日期',
                    main_station_code: '主工位代码',
                    main_workstation_name: '主工位名称',
                },

                // inquiryOd: {
                //     vin: 'VIN',
                //     inquiry_no: '问询单号',
                //     case_theme: '案件主题',
                //     inquiry_date: '问询日期',
                // },
                inquiryOd: tech.management.inquiry.zhCN,
                permission: tech.management.permission.zhCN,
            },
        },
        components: {
            businessComponents: {
                documentType: '单据类型',
                vehicleType: '车辆类型',
                dealerInformation: '经销商信息',
                reportType: '上报类型',
                dealerContact: '经销商联系人',
                contact: '联系电话',
                occupation: '职业',
                vehicleInformation: '车辆信息',
                mileage: '行驶里程/km',
                licensePlate: '车牌号',
                platform: '车型平台',
                offLineDate: '下线日期',
                vehicleUse: '车辆用途',
                refited: '是否有加改装',
                nachgefüllt: '保险情况',
                nameplateNo: '铭牌编号',
                purchaseDate: '购买日期',
                accidentInformation: '事故信息',
                timeOfAccident: '事故发生时间',
                placeOfAccident: '事故发生地点',
                roadConditions: '道路状况',
                pavementType: '路面类型',
                weather: '天气',
                vehicleCondition: '车辆状况',
                vehicleOfTheAccident: '事故发生时车辆信息',
                vehicleCollisionPosition: '车辆碰撞位置',
                vehicleRunningStatus: '车辆行驶状态',
                ownSpeed: '己方车速',
                vehicleSpeedOfTheOtherSide: '对方车速',
                personnelInsideTheVehicle: '事发车内人员情况',
                numberOfPeopleInTheCar: '车内人数',
                personalInjury: '人员受伤情况',
                distributionOfPersonnelInTheVehicle: '受伤人员所坐位置',
                descriptionOfPersonalInjury: '人员受伤情况描述',
                airbagDeployment: '气囊展开情况',
                safetyBeltWearing: '安全带佩戴情况',
                brakeBeforeTheAccident: '事发前刹车',
                turnBeforeTheIncident: '事发前转向',
                whenCorrosionOccurs: '何时发生腐蚀',
                parkingTimeHours: '停车时间（小时）',
                parkingTimeMinutes: '停车时间（分钟）',
                drivingHours: '驾驶小时',
                drivingMinutes: '驾驶分钟',
                drivingDistance: '驾驶距离',
                drivingSpeed: '驾驶速度',
                vehicleState: '事故发生时车辆状态',
                fireFightingOrNot: '是否消防救火',
                numberOfInjured: '受伤人数',
                deathToll: '死亡人数',
                vehicleHelp: '车辆求助情况',
                vehiclePassingAccident: '车辆过往事故',
                pastAccidents: '过往事故',
                isTheWarningLampOnBeforeTheEvent: '事件发生前警告灯是否亮起',
                specificIconContent: '特定图标内容',
                didYouSmellFuelBeforeTheAccident: '事发前你闻到燃油味了吗',
                didYouFeelAnyAbnormalVibrationBeforeTheAccident: '事发前有没有感觉到异常振动',
                abnormalSoundZone: '异响区',
                didYouHearAnyNoiseBeforeTheAccident: '事发前你听到什么噪音了吗',
                noiseDescription: '噪音描述',
                theAreaWhereTheVehicleSmokedBeforeTheAccident: '事发前车辆吸烟的区域',
                otherAreas: '其他区域',
                whetherTheInsuranceCompanyHasIssuedACertificate: '保险公司是否出具了证明',
                whetherTheAuthorityIssuesTheCertificate: '权威部门是否出具证明',
                isVDSReportAvailable: '是否可以获得VDS报告',
                accidentProcess: '事发过程',
                accidentProcessPlaceholder:
                    '提报人_XXXX的转述。\n' +
                    '20XX年XX月XX日凌晨XX点XX分左右，当事人XXXX驾驶车牌号为XXXXXX荣威RX50 1.5/AT的车辆沿国道由南向北方向行驶（车内共一人，驾驶员一位）。当行驶至XXX省XX市XX县XX镇XX村路段处，发现左侧驾驶舱内产生白烟。',
                accessoryMaterials: '附件材料',
                bulkDownload: '批量下载',
                materialUpload: '材料上传',
                userReadmeAttachment: '用户自述附件',
                sitePhotos: '现场照片',
                fireAccidentIdentificationLetter: '火灾事故认定书',
                insuranceRecordAttachment: '保险记录附件',
                diagnosticRecords: '诊断记录',
                temperature: '温度',
                please_select_the_reporting_type: '请选择上报类型',
                fire_accident_identification_letter_required: '火灾事故认定书必选',
                please_upload_the_fire_accident_identification_letter: '请上传火灾事故认定书',
                insurance_record_attachment_required: '保险记录附件必选',
                please_upload_the_insurance_record_attachment: '请上传保险记录附件',
                please_upload_the_diagnostic_record_attachment: '请上传诊断记录附件',
                please_upload_site_photos: '请上传现场照片',
            },
        },
        statistics: statistics.inquiryStatistics.zhCN,
        systemClassifyA: statistics.systemClassifyA.zhCN,
        systemClassifyB: statistics.systemClassifyB.zhCN,
        task: {
            china: {
                holiday: {
                    holiday_name: '节日名称',
                    holiady_time: '节日假期',
                    end_time: '结束日期',
                    holiday_add: '新增',
                    holiday_edit: '编辑',
                },
            },
            package: {
                calendar: {
                    packing: 'SMPV包装处理日',
                },
            },
        },
        logistics: {
            package: {
                cycle: {
                    transport_type: '运输类型',
                    market_type: '市场类型',
                    market_name: '市场名称',
                    market_code: '市场代码',
                    transport_cycle: '运输周期（日）',
                    Packaging_aging: '包装时效（日）',
                    OTD_aging: 'OTD时效（日）',
                },
            },
        },
        vehicle: {
            logistics: {
                expenses: {
                    atd_begin_date: 'ATD起始日期',
                    atd_end_date: 'ATD截至日期',
                    atd_date: 'ATD日期',
                    country_of_target: '发运国家',
                    dn: '外向号',
                    region: '区域',
                    model_code: '车型代码',
                    vin: 'VIN',
                    volumn: '体积',
                    vdc_name: '启运VSC',
                    pol_name: '启运港',
                    dt_content: '国内运输方式',
                    dt_distance_km: '公里数',
                    vdc_vasc_material: '国内VDC增值材料费',
                    vdc_vasc_worker: '国内VDC增值服务人工费',
                    domestic_carry: '国内运输',
                    wharf_handle: '启运港操作费',
                    wharf_sc: '启运港服务费',
                    wharf_box: '集装箱装箱',
                    medicated_fumigation: '熏蒸费用',
                    wharf_dot_check: '点检费用',
                    repair_maintenance: '修理保养',
                    refuel_storage: '加油及堆存',
                    file_cert: '文件认证',
                    supply_chain_integration: '点检费用',
                    other_domestic_carry: '其他国内费用',
                    insure: '保险费',
                    ship_money: '国际运费美金',
                    ship_money_type: '国际运费货币类型',
                    other_interal_charge: '其他国际运费',
                    other_interal_charge_type: '其他国际运费货币类型',
                    transport_company_code: '船公司',
                    vessel_name_voyage: '船名航次',
                    mode_of_transport: '海运运输方式',
                    remark: '备注',
                    create_date: '创建时间',
                },
                expensesRelationship: {
                    country_name: '国家名称',
                    country_code: '国家代码',
                    model_code: '车型代码',
                    ology_code: '科目代码',
                    ology_name: '科目名称',
                    status: '是否使用',
                    remark: '备注',
                    create_by: '创建人',
                    create_name: '创建人名',
                    create_date: '创建时间',
                    update_by: '修改人',
                    update_name: '修改人名',
                    update_date: '修改时间',
                    tache_code: '环节代码',
                    no: '否',
                    yes: '是',
                },
                expenseAccount: {
                    ology_type: '科目类型',
                    pack_name: '费用包名',
                },
                country: vehicle_country.zhCN,
            },
        },
        freightHome: {
            more: '查看更多',
            notice: '系统通知',
            state: '状态',
            notification_time: '通知时间',
        },
        freight: {
            laborUnitPrice: {
                data: '日期设置',
                service_provider_type: '服务商类型',
                job_code: '工种编码',
                name: '名称',
                name_code: '编码&名称',
                code: '编码',
                job_name: '工种名称',
                create_time: '创建时间',
                period_of_validity: '有效期',
                net_price: '净价',
                net_price_unit: '净价',
                start_time: '开始时间',
                end_time: '结束时间',
                founder: '创建人',
                vat_price: 'VAT价格',
                vat_unit: 'VAT（税率）',
                supplier_information: '供应商信息',
                basic_information: '基本信息',
                price_maintenance: '价格维护',
                spa_code: 'SAP结算方编码',
                spa_name: 'SAP结算方名称',
                spa_supplier_code: 'SAP供应商编码',
                spa_supplier_name: 'SAP供应商名称',
                spa_cost_code: 'SAP费用名称',
                spa_cost_name: 'SAP费用代码',
                type: '服务商类型',
                time_of_taking_effect: '生效时间',
                remarks: '备注',
            },
            insure: {
                unit_price: '单价',
                gds_price: 'GDS价格条款',
            },
            services: {
                materialUnitPrice: {
                    code: '增值服务编码',
                    name: '增值服务名称',
                    tiered_pricing: '有无阶梯价格',
                    pricing: '阶梯价格',
                    unit: '单位',
                    pricing_set: '阶梯价格设置',
                    specifications: '规格',
                    net_price: '净价',
                    value_added_code: '增值服务编码',
                    value_added_name: '增值服务名称',
                    vat_price: 'VAT价格',
                    vat_tax_rate: 'VAT(税率)',
                },
            },
            international: {
                container: {
                    port_code: '启运港',
                    port_name: '启运港名称',
                    dress_container_point: '送货点',
                    dress_container_point_name: '送货点名称',
                    vehicle_type: '车源类型',
                    Additional_packing_fee: '是否有额外装箱费',
                    qutsourced_or_not: '是否外采',
                    container_or_not: '额外装箱费',
                    container_num: '装箱数量',
                    pricing_unit: '计价单位',
                    sap_settlement_code: 'SAP结算方',
                    sap_settlement_name: 'SAP结算方名称',
                    destination_port_code: '目的港编码',
                    destination_port_name: '目的港名称',
                    gds_code: 'GDS船公司',
                    gds_name: 'GDS船公司名称',
                    shipping_date: '船期编码',
                    box: '箱型',
                    sap_supplier_code: 'SAP供应商',
                    sap_supplier_name: 'SAP供应商名称',
                    train_company: '火车公司',
                    train_company_code: '火车公司编码',
                    gds_train_company: 'GDS火车公司名称',
                    gds_train_company_code: 'GDS火车公司编码',
                    gds_warehouse_name: 'GDS仓库名称',
                    gds_warehouse_code: 'GDS仓库编码',
                    train_number: '火车编号',
                    warehouse_name: '仓库名称',
                    warehouse_code: '仓库编码',
                    settle_inland_transportation: '是否结算内陆运输',
                    surcharge_name: '额外费用名称',
                    rates_us: '费率(美元)',
                    surcharge_name_explain: '额外费用说明',
                    effective_date_of_price: '价格生效日期',
                    expiration_date_of_price: '价格失效日期',
                    is_surcharge: '是否有额外费用',
                },
            },
        },
    },
    validation: {
        common: {
            required: '此项必填',
            numeric: '必须为整数数字',
            decimal: '必须为数字',
            positive_interger: '必须为正整数',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `最多为${named('number')}位数`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `不能超过${named('number')}字`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `超过最大字符数(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `不少于${named('number')}字`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `必须为${named('number')}字`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `不能大于${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `不能小于${named('number')}`,
            up_to_2_decimal_places: '最多为2位小数',
            retain_up: '最多输入10位正整数，最多保留2位小数',
            retain_ups: '最多输入11位正整数，最多保留4位小数',
            input_number: '请输入数字',
            only_number_a: '只能输入字母或数字',
            translate: '只能为字母',
            positive_integer: '至少输入一个字段',
            at_least_one: '至少填写一个',
        },
        tip: {
            incomplete_information: '信息未填写完整',
            MG_information: 'MG订单员不能为空',
            MAXUS_information: 'MAXUS订单员不能为空',
        },
    },
    import: {
        incorrect_file_type: '文件类型不正确',
        exceed: '超出',
        upload_success: '上传成功',
        upload_fail: '上传失败',
        again_upload: '只能上传 jpg、png、bmp、gif 格式的图片文件，请重新上传',
    },
    export: {
        noDataToExport: '没有可导出数据',
    },
    select: {
        all: '全部',
    },
    checkbox: {
        select_all: '全选',
    },
    dialog: {
        error_message: '错误信息',
        accessory_selection: '配件选择',
    },
    homepage: {
        empty_packing_list: '空箱单',
        on_the_packaging_list: '在包装清单',
        pending_booking_list: '待订舱清单',
        documents_to_be_produced: '单证待制作',
        packaging_days_submitted: '已提交包装天数',
        is_it_overdue: '是否超期',
        packing_list_upload_date: '箱单上传日期',
        upload_days: '上传天数',
        days: '天数',
    },
    // 配件状态清单DT
    accessory: {
        dt_code: 'DT代码',
        brand: '品牌',
        order_no: '订单编号',
        dealer_code: 'Dealer Code',
        dealer_name: 'Dealer Name',
        dcs_order_no: 'DCS订单编号',
        order_type: '订单类型',
        order_create_date: '订单创建日期',
        order_approved_date: '订单审核日期',
        sub_order_no: '子订单编号',
        sub_order_type: '子订单类型',
        packlist_no: '箱单号',
        packlist_upload_date: '箱单上传日期',
        payment_method: '付款方式',
        transportation_mode: '运输方式',
        trade_terms: '贸易条款',
        port: '港口英文名称',
        original_order_part_no: '原始订单配件代码',
        actual_delivery_part_no: '实际发货配件代码',
        part_name_chinese: '配件中文名称',
        part_name_english: '配件英文名称',
        order_qty: '订货数量',
        packaging_qty: '包装数量',
        shipping_qty: '发运数量',
        fob_unit_price: 'FOB单价',
        air_freight_control_parts: '空运限制件',
        country_code: '国家代码',
        country_name: '国家名称',
        order_nos: '主订单编号',
        order_types: '主订单类型',
        sub_order_nos: '子订单号',
        dcs_order_nos: 'DCS订单编号',
        part_no: '零件代码',
        description: '配件名称',
        amount: '订货金额',
        arrival_qty: '到达数量',
        dealer: 'dealer',
    },
};
